import React, { useState, useEffect } from "react";

import * as queryString from "query-string";

import DonationWidgetForm from "forms/DonationWidgetForm";

// service
import DonationService from "services/donations/donation.service";

// schema
import { donationSchema } from "forms/validations/donation";

// data
import { amountSelect } from "forms/giftingData";

import config from "assets/config";
import CurrencyService from "services/currency/currency.service";

import { ceil } from "lodash";
export default function ForestDonationWidget({ location }) {
  const [tabKey, setTabKey] = useState(
    queryString.parse(location.search).tabName
  );

  const [amountObj, setAmountObj] = useState([]);
  const [baseAmountValue, setBaseAmountValue] = useState([]);

  const initBaseAmount = async () => {
    const cs = new CurrencyService();
    const csResponse = await cs.getINR();
    csResponse && csResponse.data && csResponse.data.rates;
    const currencyList = csResponse.data.rates;
    const ds = new DonationService();
    const responseData = await ds
      .getDonorSaplingCount("FBH")
      .catch(function (error) {
        if (error.request) {
          alert("Somthing went wrong");
        }
      });

    let baseAmount;

    if (responseData && responseData.data) {
      if (responseData.data.saplingPrice !== "") {
        baseAmount = responseData.data.saplingPrice;
      } else {
        baseAmount = config.saplingsPrice;
      }

      setBaseAmountValue(baseAmount);

      const baseAmountConversion = amountSelect.map((item) => {
        item.amount.INR = item.plantCount * baseAmount;
        const rate = 0;
        if (currencyList["USD"]) {
          const rate = ceil(
            (item.plantCount * baseAmount) / currencyList["USD"]
          );
        }
        if (rate > 0) {
          item.amount.USD = rate;
        }
        if (item.type === "input") {
          item.amount.INR = "";
          item.amount.USD = "";
        }
        return item;
      });

      setAmountObj(baseAmountConversion);
    } else {
      setAmountObj(amountSelect);
    }
  };

  useEffect(() => {
    initBaseAmount();
  }, []);

  return (
    <div className="donation-gifting-form-wrapper">
      <div className="container p-0">
        <div className="img-form-row row g-0">
          <div className="donation-gifting-wrapper donation-gifting-wrapper-padding col-lg-12 col-md-12">
            <DonationWidgetForm
              formType="donation"
              validationSchema={donationSchema}
              amountSelect={amountObj}
              baseAmountValue={baseAmountValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
