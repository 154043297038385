// images
import plant from 'assets/images/dg/plant.png';
import sakura from 'assets/images/dg/sakura.png';
import sapling from 'assets/images/dg/sapling.png';
import saplingAmount from 'assets/images/dg/sapling-amount.png';

const amountOptions = [
  {
    id: 1,
    name: 'INR',
    label: 'INR',
  },
  {
    id: 2,
    name: 'USD',
    label: 'USD',
  },
];

const donationData = {
  userProfile: {
    userId: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    memberId: '',
    taxId: '',
    citizenshipCountry: '',
    role: null,
  },
  lineItems: [
    {
      id: '',
      donationItem: {
        id: '30',
        name: '',
        description: '',
        region: '',
        currency: '',
        billingAccountName: '',
        isActive: false,
      },
      currency: 'INR',
      amount: '12',
      recurringStartDate: '',
      recurringFrequency: '',
      extras: {
        donorCount: '1',
        donorName: '',
        donorEmailAddress: '',
        donorPhoneNumber: '',
        donationType: '',
        giftReceiverName: '',
        giftReceiverEmailAddress: '',
        giftReceiverPhoneNumber: '',
        giftMessage: '',
        projectId: '',
      },
    },
  ],
  currency: null,
  amount: null,
  description: null,
  clientSuccessRedirectUrl: '',
  clientFailureRedirectUrl: '',
  clientId: 'me',
  extras: {
    projects: [''],
    identityType: '',
    identityNumber: '',
    packageDetail: '',
  },
};

const indiaOptions = [
  {
    id: 1,
    name: 'Pancard',
    label: 'PAN number',
  },
  {
    id: 2,
    name: 'Passport',
    label: 'Passport No',
  },
  {
    id: 3,
    name: 'Aadhar Card',
    label: 'Aadhar number',
  },
  {
    id: 4,
    name: 'Aadhar Card',
    label: 'Voter Id',
  },
  {
    id: 5,
    name: 'Driving License',
    label: 'Driving License',
  },
  {
    id: 6,
    name: 'Ration Card',
    label: 'Ration Card No',
  },
];

const overseasOptions = [
  {
    id: 1,
    name: 'Passport',
    label: 'Passport No',
  },
  {
    id: 2,
    name: 'Pancard',
    label: 'Indian Pancard',
  },
];

const identityNumberRegexes = {
  Pancard: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/,
  'Aadhar Card': /^[0-9]{12}$/,
};

const plantCount = (amount, baseAmount) => {
  if (amount) {
    const pc = Math.floor(amount / baseAmount);
    return pc;
  } else {
    return 0;
  }
};

const amountSelect = [
  {
    id: 1,
    name: 'Plant 10 Trees',
    plantCount: 10,
    amount: {
      INR: 0,
      USD: 24,
    },
    type: 'radio',
    img: plant,
    checked: false,
  },
  {
    id: 2,
    name: 'Plant 100 Trees',
    plantCount: 100,
    amount: {
      INR: 0,
      USD: 240,
    },
    type: 'radio',
    img: sakura,
    checked: true,
  },
  {
    id: 3,
    name: 'Plant 1,000 Trees',
    plantCount: 1000,
    amount: {
      INR: 0,
      USD: 2400,
    },
    type: 'radio',
    img: sapling,
    checked: false,
  },
  {
    id: 4,
    plantCount: null,
    name: 'Help as much as you want',
    amount: {
      INR: 0,
      USD: 0,
    },
    type: 'input',
    img: saplingAmount,
    checked: false,
  },
];

export { amountSelect, amountOptions, donationData, indiaOptions, overseasOptions, identityNumberRegexes, plantCount };
